import React from 'react';
import { Box } from '@smooth-ui/core-sc';

// Components
import Header from 'components/Header';
import Notification from 'components/Notification';
import Footer from 'components/Footer';
import MobileMenu from 'components/MobileMenu';

// Styles
import GlobalStyle from 'utils/global.css';

// Assets
import background from './background.svg';

const Layout = ({ children }) => {
  return (
    <Box
      backgroundImage={{ xs: 'none', md: `url(${background})` }}
      backgroundSize={{ md: 'contain', xl: 'auto' }}
      backgroundPosition="40px 0"
      backgroundRepeat="no-repeat"
    >
      <GlobalStyle />
      <Notification />
      <Header />
      <MobileMenu />
      {children}
      <Footer />
    </Box>
  );
};

export default Layout;
