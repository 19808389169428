// Dependencies
import React from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';
import { Typography } from '@smooth-ui/core-sc';

// Components
// import Figure from './figure';
// import Slideshow from './slideshow';

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case 'h1':
          return <Typography variant="h1">{props.children}</Typography>;
        case 'blockquote':
          return <blockquote>{props.children}</blockquote>;

        default:
          return (
            <Typography as="p" mb={2} fontSize={16}>
              {props.children}
            </Typography>
          );
      }
    }
  }
};

const BlockContent = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />;

export default BlockContent;
