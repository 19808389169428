import { createGlobalStyle } from 'styled-components';
import { Normalize, th } from '@smooth-ui/core-sc';
import 'destyle.css';
import 'fonts/times-sans-serif/stylesheet.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GlobalStyle = createGlobalStyle`
  ${Normalize};

  html {
    font-size: 62.5%;
  }

  body {
    line-height: 1.5625;
    font-size: 1.6rem;
    background-color: ${th('green')};
    font-family: ${th('fontFamily')};
    color: ${th('primary')};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: 'pnum';
    font-feature-settings: 'pnum';
    font-variant-numeric: proportional-nums;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ::selection {
    color: ${th('white')};
    background: ${th('black')};
  }


  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
    margin: 0;
  }


  ul,
  ol {
    padding: 0 0 0 20px !important;

    /* list-style-position: inside !important; */
  }

  ol {
    list-style-type: decimal !important;
  }

  ul {
    list-style-type: circle !important;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    border: 1px solid currentColor;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    line-height: 1.44;
    margin-bottom: 2rem;
  }

  /* .carousel__slider--spotlight {
    padding: 4px 0 50px;

      overflow: visible !important;
    @media (max-width: 768px) {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
  }

  .carousel__inner-slide {
    &:focus {
      outline: none;
    }
  }


  .carousel--spotlight {
    overflow: hidden;
    padding: 4px 16px 60px;

    @media (max-width: 768px) {
      padding: 4px 0 60px;
    }
  } */

  .carousel--property {
    .slick-slide:not(.slick-current) {
      pointer-events: none;
    }
  }

  .carousel__slider--spotlight {
    padding: 0 10px;

    @media (max-width: 767px) {
      width: calc(100% - 15px);
      margin: 0 auto;
    }

    .slick-list {
      overflow: visible;
    }
  }

  .slick-list {
    outline: none !important;
  }


  .carousel__slider--team {
    padding: 0 10px;

    @media (max-width: 767px) {
      width: calc(100% - 15px);
      margin: 0 auto;
    }

    .slick-list {
      overflow: visible;
    }

    .slick-track{
      display: flex;
      align-items: stretch;
      justify-content: center;

      .slick-slide{
        height: auto;

        > div {
          height: 100%;
          > *,
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .slick-dots {
    padding: 0 !important;
  }

  .carousel__slider--insurance {
    .slick-track {
      display: flex;
      align-items: stretch;
      justify-content: center;

      .slick-slide {
        height: auto;

        > div {
          height: 100%;
          > *,
          &:focus {
            outline: none;
          }
        }
      }
    }

    .slick-dots {
      bottom: -85%;

      li {
        margin: 0;
      }

      button::before {
        color: ${th('primary')};
      }

      .slick-active button::before {
        color: ${th('primary')};
        opacity: 1;
      }
    }
  }

`;

export default GlobalStyle;
