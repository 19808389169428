import React, { useState } from 'react';
import { graphql } from 'gatsby';
import filter from 'lodash.filter';
import debounce from 'lodash.debounce';

import Slider from 'rc-slider';
import Select from 'react-select';
import 'rc-slider/assets/index.css';

import { Grid, Row, Col, Box, Typography } from '@smooth-ui/core-sc';
import SEO from 'components/seo';
import Layout from 'components/Layout';
import PropertyCard from 'components/PropertyCard';
import Button from 'components/Button';
import Contact from 'components/Forms/Contact';

import smoothTheme from 'utils/theme';
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from 'lib/helpers';
import { numberWithCommas, dedupeSort } from 'utils/helpers';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

export const query = graphql`
  query IndexPageQuery {
    properties: allSanityProperty(
      filter: { status: { ne: "archived" } }
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          title
          shortDescription
          transaction
          slug {
            current
          }
          status
          price
          type {
            id
            title
          }
          location {
            city
            zip
            label
            value
            lat
            lng
          }
          photos {
            asset {
              fluid(toFormat: JPG, maxWidth: 550) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    page: sanityPage(_id: { eq: "estate" }) {
      title
      contact {
        title
        image {
          asset {
            fixed(toFormat: JPG, width: 565, height: 750) {
              src
            }
          }
        }
      }
    }
  }
`;

const customStyles = {
  control: styles => ({
    ...styles,
    padding: 8,
    minHeight: 51,
    borderColor: smoothTheme.green,
    ':hover': {
      borderColor: smoothTheme.teal
    }
  }),
  indicatorSeparator: styles => ({ ...styles, display: 'none' }),
  clearIndicator: styles => ({
    ...styles,
    padding: 0,
    color: smoothTheme.teal,
    ':hover': {
      color: smoothTheme.teal40
    }
  }),
  dropdownIndicator: styles => ({
    ...styles,
    padding: 0,
    color: smoothTheme.teal,
    ':hover': {
      color: smoothTheme.teal40
    }
  }),
  valueContainer: styles => ({ ...styles, padding: 0 }),
  option: (provided, state) => ({
    ...provided,
    color: smoothTheme.teal,
    backgroundColor: state.isFocused ? smoothTheme.teal90 : smoothTheme.white,
    ':hover': {
      backgroundColor: smoothTheme.teal,
      color: 'white'
    }
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: smoothTheme.green
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: smoothTheme.teal,
    padding: 8,
    paddingLeft: 8
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: smoothTheme.white,
    ':hover': {
      backgroundColor: smoothTheme.teal,
      color: 'white'
    }
  })
};

const IndexPage = ({ data }) => {
  /**
   * * Data management
   */
  const properties = (data || {}).properties
    ? mapEdgesToNodes(data.properties).filter(filterOutDocsWithoutSlugs)
    : [];
  const page = data.page;

  const propertyTypesOptions = dedupeSort(
    properties.map(item => ({
      value: item.type.id,
      label: item.type.title
    }))
  );

  const cleanTypes = propertyTypesOptions.map(type => type.label);

  const locationsOptions = dedupeSort(
    properties.map(item => ({
      value: item.location.value,
      label: item.location.label
    }))
  );

  const defaultMaxPrice = 1000000;
  const highestPropertyPrice = Math.max.apply(Math, properties.map(property => property.price));
  const maxPrice = highestPropertyPrice > defaultMaxPrice ? highestPropertyPrice : defaultMaxPrice;

  const cleanLocations = locationsOptions.map(type => type.label);

  /**
   * * State
   */
  // const [transaction, setTransaction] = useState("forSale");
  const maxDefaultAmount = 8;
  const [showAll, setShowAll] = useState(false);
  const [type, setType] = useState(cleanTypes);
  const [location, setLocation] = useState(cleanLocations);
  const [price, setPrice] = useState({
    min: 0,
    max: 600000
  });

  // Set Price
  const onSliderChange = value => {
    setPrice({ min: value[0], max: value[1] });
  };

  // Set Type or fallback to default
  const onTypeChange = values => {
    const cleanValues = values.map(value => value.label);
    if (values.length) {
      setType(cleanValues);
    } else {
      setType(cleanTypes);
    }
  };

  // Set Location or fallback to default
  const onLocationChange = values => {
    const cleanValues = values.map(value => value.label);

    if (values.length) {
      setLocation(cleanValues);
    } else {
      setLocation(cleanLocations);
    }
  };

  const filteredProperties = filter(
    properties,
    property =>
      type.some(t => property.type.title === t) &&
      location.some(loc => property.location.label === loc) &&
      property.price >= price.min &&
      property.price <= price.max
  );

  const slicedFilteredProperties = filteredProperties.slice(
    0,
    showAll ? filteredProperties.length : maxDefaultAmount
  );

  return (
    <Layout>
      <SEO title={'Vastgoed'} description={'Description'} keywords={['keywords']} />

      <Grid mb={{ xs: 6, md: 12 }}>
        <Row>
          <Col xs={12} sm={6} md={6} lg={4} mb={4}>
            <Box backgroundColor="white" p={3} height={1}>
              <Typography mb={3} variant="h1">
                Woning zoeken
              </Typography>
              <Typography display="block" htmlFor="type" as="label" mb={1}>
                Type woning
              </Typography>
              <Box mb={3}>
                <Select
                  placeholder="Kies je type woning"
                  inputId="type"
                  options={propertyTypesOptions}
                  isClearable
                  isMulti
                  styles={customStyles}
                  onChange={values => onTypeChange(values)}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: smoothTheme.teal
                    }
                  })}
                />
              </Box>
              <Typography display="block" htmlFor="location" as="label" mb={1}>
                Regio
              </Typography>
              <Box mb={3}>
                <Select
                  placeholder="Kies je regio"
                  inputId="location"
                  options={locationsOptions}
                  isClearable
                  isMulti
                  styles={customStyles}
                  classNamePrefix="react-select"
                  onChange={values => onLocationChange(values)}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: smoothTheme.teal
                    }
                  })}
                />
              </Box>
              <Typography display="block" htmlFor="range" as="label" mb={1}>
                Prijs tussen
              </Typography>
              <Box mb={3}>
                <Range
                  id="range"
                  min={0}
                  max={maxPrice}
                  step={5000}
                  defaultValue={[price.min, price.max]}
                  pushable={10000}
                  tipFormatter={value => `€ ${numberWithCommas(value)}`}
                  onChange={debounce(onSliderChange, 50)}
                  railStyle={{ backgroundColor: smoothTheme.green }}
                  trackStyle={[{ backgroundColor: smoothTheme.teal }]}
                  handleStyle={[{ borderColor: smoothTheme.teal }]}
                />
              </Box>
              <Row gutter={10}>
                <Col gutter={10}>
                  <Box
                    px={{ xs: 2, sm: 1, lg: 3 }}
                    mb={1}
                    py={2}
                    border="1px solid"
                    borderColor="green"
                  >
                    € {numberWithCommas(price.min)}
                  </Box>
                </Col>
                <Col gutter={10}>
                  <Box px={{ xs: 2, sm: 1, lg: 3 }} py={2} border="1px solid" borderColor="green">
                    € {numberWithCommas(price.max)}
                  </Box>
                </Col>
              </Row>
            </Box>
          </Col>

          {slicedFilteredProperties.map((property, i) => (
            <Col key={i} xs={12} sm={6} md={6} lg={4} mb={4}>
              <PropertyCard property={property} />
            </Col>
          ))}
        </Row>

        {!showAll && filteredProperties.length > maxDefaultAmount && (
          <Box mt={10} textAlign="center">
            <Button onClick={() => setShowAll(true)}>Meer panden tonen</Button>
          </Box>
        )}
      </Grid>
      <Contact
        formName="Vastgoed pagina"
        title={page.contact.title}
        image={page.contact.image.asset.fixed.src}
      />
    </Layout>
  );
};

export default IndexPage;
