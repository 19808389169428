import uniqBy from 'lodash.uniqby';
import orderBy from 'lodash.orderby';

export function numberWithCommas(num) {
  let parts = num.toString().split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
}

export function dedupeSort(array) {
  return orderBy(uniqBy(array, 'value'), ['value', 'asc']);
}

export function dedupe(array, key) {
  return uniqBy(array, key);
}
