import React from 'react';
import Link from 'components/Link';
import Img from 'gatsby-image';
import { Box, Typography } from '@smooth-ui/core-sc';
import { numberWithCommas } from 'utils/helpers';

// Context
import UiContext from 'context/UiContext';

import Card from 'components/Card';

const PropertyCard = ({ property }) => {
  const isSold = property.status === 'sold';
  const isNew = property.status === 'new';
  const isInOption = property.status === 'in_option';

  return (
    <UiContext.Consumer>
      {({ isIE }) => (
        <Box height={'100%'} to={!isSold && `/vastgoed/${property.slug.current}`} as={!isSold && Link}>
          <Card
            display={isIE ? 'block' : 'flex'}
            flexDirection="column"
            height={1}
            isSold={isSold}
            backgroundColor="white"
          >
            {isNew && (
              <Typography
                textAlign="center"
                backgroundColor="red"
                zIndex={1}
                position="absolute"
                top={0}
                left={0}
                width={1}
                display="block"
                color="white"
                py={'2px'}
                textTransform="uppercase"
              >
                Nieuw
              </Typography>
            )}
            {isSold && (
              <Typography
                textAlign="center"
                backgroundColor="teal"
                zIndex={1}
                position="absolute"
                top={0}
                left={0}
                width={1}
                display="block"
                color="white"
                py={'2px'}
                textTransform="uppercase"
              >
                Verkocht
              </Typography>
            )}
            {isInOption && (
              <Typography
                textAlign="center"
                backgroundColor="orange"
                zIndex={1}
                position="absolute"
                top={0}
                left={0}
                width={1}
                display="block"
                color="teal"
                py={'2px'}
                textTransform="uppercase"
              >
                In Optie
              </Typography>
            )}
            <Img fluid={{ ...property.photos[0].asset.fluid, aspectRatio: 1 / 0.85 }} />
            <Box
              borderBottom="1px solid"
              borderColor="green"
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                fontWeight="bold"
                fontSize={{ xs: 3, sm: 2, md: 3 }}
              >{`€ ${numberWithCommas(property.price)}`}</Typography>
              <Typography>{property.type.title}</Typography>
            </Box>
            <Box backgroundColor="white" p={3} display="flex" flexDirection="column" flex={1}>
              <Typography display="block" mb={2} opacity={0.5} lineHeight={1.4}>
                {property.shortDescription}
              </Typography>
              <Typography mt="auto" display="block">
                {property.location.city}
              </Typography>
            </Box>
          </Card>
        </Box>
      )}
    </UiContext.Consumer>
  );
};

export default PropertyCard;
