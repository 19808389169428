import React from 'react';

import { Box } from '@smooth-ui/core-sc';

const Wrapper = ({ children, ...rest }) => {
  return (
    <Box maxWidth={1226} width={1} mx="auto" px={{ xs: 3, md: 6 }} {...rest}>
      {children}
    </Box>
  );
};

export default Wrapper;
