import { styled, Box, transition, th } from '@smooth-ui/core-sc';
import { easings } from 'utils/theme';
import posed from 'react-pose';

const ease = {
  duration: 750,
  ease: easings.easeOutExpo
};

const sidebarProps = {
  open: {
    opacity: 1,
    staggerChildren: 75,
    transition: ease,
    applyAtStart: { visibility: 'visible' }
  },
  closed: { opacity: 0, transition: ease, applyAtEnd: { visibility: 'hidden' } }
};

const PosedWrapper = posed.div(sidebarProps);

export const Wrapper = styled(PosedWrapper)`
  opacity: 0;
  height: 100%;
  z-index: ${th('zMobileMenu')};
  background-color: ${th('teal')};
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Menu = styled(Box)`
  color: ${th('green')};
  max-width: 100%;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  justify-content: center;
  grid-gap: 2.3rem;
  text-align: center;
`;

export const MenuItem = posed.div({
  open: { y: 0, opacity: 1, transition: ease },
  closed: { y: 10, opacity: 0, transition: ease }
});

export const MenuLink = styled(Box)`
  ${transition('background-color 0.25s, color 0.25s')};
  border-bottom: 2px solid transparent;
  padding: 6px 0;

  &.active {
    border-bottom: 2px solid ${th('green')};
  }
`;
