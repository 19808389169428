// Dependencies
import React from 'react';
import { Box, Typography, Breakpoint } from '@smooth-ui/core-sc';
import { Slider } from 'react-burgers';
import { Location } from '@reach/router';

// Context
import UiContext from 'context/UiContext';

// Components
import Wrapper from 'components/Wrapper';
import Link from 'components/Link';

// Utils
import theme from 'utils/theme';

// Styles
import * as S from './Header.css';

// Helper
const MenuItem = ({ to, children, ...rest }) => {
  const activeClassName = 'active';
  return (
    <S.MenuItem as={Link} partial activeClassName={activeClassName} to={to} {...rest}>
      <Typography fontSize={{ md: 14, lg: 16 }}>{children}</Typography>
    </S.MenuItem>
  );
};

const Header = () => {
  return (
    <Box py={2} mb={4} zIndex={theme.zHeader} position="relative">
      <Wrapper>
        <Box display="flex" alignItems="center">
          <Box to="/" as={Link}>
            <Breakpoint up="md">
              <Location>
                {({ navigate, location }) => <S.Logo isHome={location.pathname === '/'} />}
              </Location>
            </Breakpoint>
            <Breakpoint down="md">
              <UiContext.Consumer>
                {({ mobileMenuIsOpen, toggleMobileMenu }) => (
                  <S.LogoMobile
                    color={mobileMenuIsOpen ? theme.green : theme.teal}
                    mobileMenuIsOpen={mobileMenuIsOpen}
                  />
                )}
              </UiContext.Consumer>
            </Breakpoint>
          </Box>

          <UiContext.Consumer>
            {({ isIE }) => (
              <S.Menu display={{ xs: 'none', md: isIE ? 'flex' : 'grid' }} ml="auto">
                <MenuItem mx={isIE ? '1.5rem' : 0} to="/vastgoed">
                  Vastgoed
                </MenuItem>
                <MenuItem mx={isIE ? '1.5rem' : 0} to="/kredieten">
                  Kredieten
                </MenuItem>
                <MenuItem mx={isIE ? '1.5rem' : 0} to="/verzekeringen">
                  Verzekeringen
                </MenuItem>
                <MenuItem mx={isIE ? '1.5rem' : 0} to="/over-ons">
                  Over ons
                </MenuItem>
                <MenuItem mx={isIE ? '1.5rem' : 0} to="/contact">
                  Contact
                </MenuItem>
              </S.Menu>
            )}
          </UiContext.Consumer>

          <Box position="relative" display={{ xs: 'block', md: 'none' }} ml="auto">
            <UiContext.Consumer>
              {({ mobileMenuIsOpen, toggleMobileMenu }) => (
                <Slider
                  padding="0"
                  width={20}
                  lineHeight={2}
                  lineSpacing={4}
                  onClick={() => toggleMobileMenu()}
                  active={mobileMenuIsOpen}
                  color={mobileMenuIsOpen ? theme.green : theme.teal}
                />
              )}
            </UiContext.Consumer>
          </Box>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default Header;
