import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Typography, styled, th } from '@smooth-ui/core-sc';
import BlockContent from 'components/block-content';
import theme from 'utils/theme';
import UiContext from 'context/UiContext';

const query = graphql`
  query NotificationQuery {
    sanityNotification {
      title
      _rawContent
      isVisible
    }
  }
`;

const StyledBox = styled(Box)`
  * {
    margin: 0 !important;
  }
`;

const Notification = () => {
  return (
    <StaticQuery
      query={query}
      render={({ sanityNotification }) => {
        if (sanityNotification.isVisible) {
          return (
            <UiContext.Consumer>
              {({ mobileMenuIsOpen, toggleMobileMenu }) =>
                !mobileMenuIsOpen && (
                  <StyledBox
                    backgroundColor="primary"
                    color="white"
                    fontFamily="TimesSansSerif"
                    textAlign="center"
                    py={2}
                    px={2}
                    position="relative"
                    zIndex={theme.zNotification}
                  >
                    <BlockContent blocks={sanityNotification._rawContent} style={{}} />
                  </StyledBox>
                )
              }
            </UiContext.Consumer>
          );
        }
      }}
    />
  );
};

export default Notification;
