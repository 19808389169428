import React from 'react';
import { Breakpoint, Typography } from '@smooth-ui/core-sc';
import Link from 'components/Link';
import UiContext from 'context/UiContext';

import * as S from './MobileMenu.css';

const activeClassName = 'active';

const MenuLink = ({ to, children, ...rest }) => (
  <S.MenuItem>
    <S.MenuLink as={Link} partial activeClassName={activeClassName} to={to} {...rest}>
      <Typography>{children}</Typography>
    </S.MenuLink>
  </S.MenuItem>
);

const MobileMenu = () => {
  return (
    <Breakpoint down="md">
      <UiContext.Consumer>
        {({ mobileMenuIsOpen, toggleMobileMenu }) => (
          <S.Wrapper initialPose="closed" pose={mobileMenuIsOpen ? 'open' : 'closed'}>
            <S.Menu>
              <MenuLink onClick={() => toggleMobileMenu()} to="/vastgoed">
                Vastgoed
              </MenuLink>
              <MenuLink onClick={() => toggleMobileMenu()} to="/kredieten">
                Kredieten
              </MenuLink>
              <MenuLink onClick={() => toggleMobileMenu()} to="/verzekeringen">
                Verzekeringen
              </MenuLink>
              <MenuLink onClick={() => toggleMobileMenu()} to="/over-ons">
                Over ons
              </MenuLink>
              <MenuLink onClick={() => toggleMobileMenu()} to="/contact">
                Contact
              </MenuLink>
            </S.Menu>
          </S.Wrapper>
        )}
      </UiContext.Consumer>
    </Breakpoint>
  );
};

export default MobileMenu;
