import { styled, Box, transition, th } from '@smooth-ui/core-sc';

import LogoSquareSVG from 'images/logo-square.svg';
import LogoSVG from 'images/logo.svg';

export const Menu = styled(Box)`
  grid-auto-flow: column;
  grid-gap: 3rem;
  align-items: center;
`;

export const MenuItem = styled(Box)`
  ${transition('background-color 0.25s, color 0.25s, border-color 0.25s')};
  padding: 6px 0;
  border-bottom: 2px solid transparent;

  &:hover,
  &.active {
    border-bottom: 2px solid ${th('primary')};
  }
`;

export const Logo = styled(LogoSquareSVG)`
  width: 95px;
  height: 94px;
  transform-origin: top left;
  transform: ${props => (props.isHome ? 'scale(1.4)' : 'none')};
`;

export const LogoMobile = styled(LogoSVG)`
  width: 100px;
  height: 50px;
`;
