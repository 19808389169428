import React from 'react';
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';
import { Breakpoint, Row, Col, Box, Typography } from '@smooth-ui/core-sc';
import { FaFacebook, FaLinkedinIn, FaGoogle } from 'react-icons/fa';
import Link from 'components/Link';
import { Location } from '@reach/router';

const query = graphql`
  query FooterQuery {
    companyInfo: sanityCompanyInfo {
      name
      address1
      address2
      zipCode
      city
      country
      email
      phone
      mobile
      openHours
      closedHours
      facebookUrl
      linkedInUrl
      googleUrl
      contactMap {
        asset {
          fluid(toFormat: JPG, maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

const Footer = () => {
  return (
    <StaticQuery
      query={query}
      render={({ companyInfo }) => {
        return (
          <Location>
            {({ navigate, location }) =>
              location.pathname !== '/contact' && (
                <Box as="footer" backgroundColor="green">
                  <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                    <Col
                      order={{ xs: 2, md: 1 }}
                      gutter={0}
                      xs={12}
                      md={6}
                      pr={{ xs: 0, md: 8, lg: 15 }}
                    >
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://goo.gl/maps/JXTod6imNnS2"
                      >
                        <Img
                          fluid={{ ...companyInfo.contactMap.asset.fluid, aspectRatio: 600 / 500 }}
                          style={{ height: '100%' }}
                        />
                      </a>
                    </Col>
                    <Col xs={12} md={6} order={{ xs: 1, md: 2 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        height={1}
                        px={{ xs: 0, md: 0 }}
                        py={{ xs: 3, md: 6, lg: 6 }}
                        maxWidth={555}
                      >
                        {companyInfo && (
                          <Box width={1}>
                            <Box mb={4}>
                              <Typography display="block" fontWeight="bold">
                                {companyInfo.name}
                              </Typography>
                              <Typography display="block">{companyInfo.address1}</Typography>
                              {companyInfo.address2 && (
                                <Typography display="block">{companyInfo.address2}</Typography>
                              )}
                              <Typography display="block">
                                {companyInfo.zipCode} {companyInfo.city}
                              </Typography>
                            </Box>
                            <Box mb={4}>
                              <Typography display="block" fontWeight="bold">
                                Contact
                              </Typography>
                              <Typography display="block">
                                <a href={`tel:${companyInfo.phone}`}>Tel: {companyInfo.phone}</a>
                              </Typography>
                              <Typography display="block">
                                <a href={`tel:${companyInfo.mobile}`}>GSM: {companyInfo.mobile}</a>
                              </Typography>
                              <Typography display="block">
                                <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a>
                              </Typography>
                            </Box>
                            <Box mb={4}>
                              <Typography display="block" fontWeight="bold">
                                Openingsuren
                              </Typography>
                              <Typography display="block">{companyInfo.openHours}</Typography>
                              <Typography display="block">{companyInfo.closedHours}</Typography>
                            </Box>
                            <Box mb={4}>
                              {companyInfo.facebookUrl && (
                                <Typography mr={2} as="a" href={companyInfo.facebookUrl}>
                                  <FaFacebook />
                                </Typography>
                              )}
                              {companyInfo.linkedInUrl && (
                                <Typography mr={2} as="a" href={companyInfo.linkedInUrl}>
                                  <FaLinkedinIn />
                                </Typography>
                              )}
                              {companyInfo.googleUrl && (
                                <Typography as="a" href={companyInfo.googleUrl}>
                                  <FaGoogle />
                                </Typography>
                              )}
                            </Box>
                            <Box mb={4}>
                              <Link to="/privacy">
                                <Typography
                                  fontSize={12}
                                  style={{ textDecoration: 'underline' }}
                                  opacity={0.5}
                                >
                                  Privacy verklaring
                                </Typography>
                              </Link>
                            </Box>
                            <Box mb={4}>
                              <Typography
                                display="block"
                                fontSize={12}
                                opacity={0.5}
                                lineHeight={1.58}
                              >
                                Erkend vastgoedmakelaar-bemiddelaar | BIV: 204 058 - België | KBO:
                                0898.927.110 | Onderworpen aan de{' '}
                                <Typography style={{ textDecoration: 'underline' }}>
                                  <Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to="https://www.biv.be/de-vastgoedmakelaar/deontologie-van-de-vastgoedmakelaar"
                                  >
                                    deontologische code van het BIV
                                  </Link>
                                </Typography>
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                display="block"
                                fontSize={12}
                                opacity={0.5}
                                lineHeight={1.58}
                              >
                                Ons kantoor is gehouden tot het naleven van de{' '}
                                <Typography style={{ textDecoration: 'underline' }}>
                                  <a
                                    href="/AssurMifid-Belangenconflict.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    AssurMiFID-gedragsregels
                                  </a>
                                </Typography>{' '}
                                | Verzekeringsmakelaar, FSMA: 19480 | Het register van de
                                verzekerings-tussenpersonen wordt bijgehouden door  de FSMA, te 1000
                                Brussel, Congresstraat 12-14 en is terug te vinden op{' '}
                                <Typography style={{ textDecoration: 'underline' }}>
                                  <Link
                                    to="https://www.fsma.be/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    www.fsma.be
                                  </Link>
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Col>
                  </Box>
                </Box>
              )
            }
          </Location>
        );
      }}
    />
  );
};

export default Footer;
